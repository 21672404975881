<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getCjdak,
    editCjdak,
    deleteCjdak
  } from "@/api/admin/exam/createExamku.js"
  import {
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import changeExam from '@/components/change-exam.vue'
  // import {
  //   checkFile,
  // } from "@/api/index.js"
  /**
   * 档案
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      changeExam


    },
    data() {
      return {
        title: "考试报名档案库/",
        title2: "2021年二级造价工程师职业资格增报专业考试档案库",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "报名档案库",
            active: true
          }
        ],
        tableList: [],
        exam: [],
        showmodal: false,
        fileId: "",
        excelFields: [],
        addForm: {},
        years: [],
        ksbmbh: "",
        ksnf: "",
        ksmc: "",
        exportTitle: "考试档案库导出",
        importTitle: "考试档案库导入",
        module: "CJDAK",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          ksnf: "",
          keyword: "",
          keyParam: "zjhm"
        },
        uploadLimit: {},
        sfType: {
          1: "居民身份证",
          5: "部队离退休证",
          9: "境外永久居住证",
          2: "军官证",
          6: "香港特区护照/身份证明",
          A: "护照",
          3: "士兵证",
          7: "澳门特区护照/身份证明",
          B: "户口薄",
          4: "文职干部证",
          8: "台湾居民来往大陆通行证",
          Z: "其他",
        },
      };
    },
    methods: {
      // getKsxx(year) {
      //   this.ksnf = year
      // },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      getList() {
        getCjdak({
          ...this.pageData,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      getksmc() {
        let val = this.select.ksbmbh
        let obj = this.exam.find(k => {
          return k.ksbmbh === val
        })
        this.ksmc = obj.ksmc

      },
      check(file) {
        var _this = this;
        var fileExt = file.name.split(".")[file.name.split(".").length - 1];
        var sclx = file.name.indexOf("." + fileExt)
        if (sclx == -1) {
          _this.$notify.warning({
            message: "上传文件类型错误"
          });
          return false;
        } else {
          _this.$notify.success({
            message: "上传成功"
          })
          return true;
        }

      },
      // 上传成功后调用
      uploadSuccess() {
        this.getList()
      },
      // 成绩库上传
      uploadFile() {
        var _this = this;
        _this.$importModal().show({
          title: this.importTitle,
          module: this.module,
          ksbmbh: this.ksbmbh,
          success: this.uploadSuccess
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改档案库"
        this.addForm.ksbmbh = this.ksbmbh
      },
      editDak() {
        let formDate = this.addForm
        formDate.sid = this.addForm.sid
        editCjdak(formDate).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: res.message,
              duration: 3000,
            })
            this.showmodal = false
            this.getList()
          }
        })
      },
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.ksxm}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteCjdak({
            ksbmbh: obj.ksbmbh ? obj.ksbmbh : this.ksbmbh,
            sids: obj.sid
          }).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //获取切换的考试
      getLastExam(params) {
        if (params) {
          this.getList({
            ksbmbh: this.ksbmbh,
          })
        } else {
          getNowExam().then(res => {
            if (res.status) {
              if (res.data) {
                this.ksbmbh = res.data.ksbmbh
                this.ksmc = res.data.ksmc
                this.pageData.ksnf = res.data.ksnf
              }
            }
            this.getList({
              ksbmbh: this.ksbmbh,
            })
          })

        }
      }
    },
    mounted() {
      this.ksnf = new Date().getFullYear();
      this.getYear()
      this.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ''
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ''
      this.getLastExam(this.ksbmbh)
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <input placeholder="输入证件号查询" v-model="pageData.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="uploadFile"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入档案库</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'xlsx', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'dbf', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 6%;">档案号</th>
<!--                    <th>证件类型</th>-->
                    <th style="width: 10%;">证件号</th>
                    <th style="width: 6%;">姓名</th>
<!--                    <th style="width: 4%;">性别</th>-->
<!--                    <th style="width: 4%;">民族</th>-->
<!--                    <th style="width: 6%;">出生日期</th>-->
                    <th style="width: 6%;">报考级别码 </th>
                    <th style="width: 5%;">报考级别 </th>
                    <th style="width: 6%;">报考专业码 </th>
                    <th>报考专业 </th>
                    <th>考区名称 </th>
                    <th style="width: 7%;">专业通过时间</th>
                    <th style="width: 7%;">科目一通过时间</th>
                    <th style="width: 7%;">科目二通过时间</th>
                    <th style="width: 4%;">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>
                      {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                    </td>
                    <td>{{obj.dah}} </td>
<!--                    <td>{{ sfType[obj.zjlb] }}</td>-->
                    <td>{{obj.zjhm}}</td>
                    <td>
                      <b-button variant="link" class="font-blue h30">{{obj.ksxm}}</b-button>
                    </td>
                   <!-- <td>{{obj.xb}}</td>
                    <td>{{obj.mz}}</td>
                    <td>{{obj.csrq}}</td>-->
                    <td>{{obj.bkjb}} </td>
                    <td>{{obj.bkjbmc}}</td>
                    <td>{{obj.bkzy}}</td>
                    <td>{{obj.bkzymc}}</td>
                    <td>{{obj.kqmc}}</td>
                    <td>{{obj.zytg}}</td>
                    <td>{{obj.kmtg_1}}</td>
                    <td>{{obj.kmtg_2}}</td>

                    <td class="tab-icon"><i class="iconfont icon-edit-two " @click="editItem(obj)"></i> <i
                        class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <changeExam :ksbmbh="ksbmbh"></changeExam>
    <b-modal id="addClass" v-model="showmodal" centered title="考试档案库信息编辑" title-class="font-18" hide-footer>
      <div style="max-height: 500px;overflow-y: auto;">
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">档案号：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="40" v-model="addForm.dah"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">证件号：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="18" v-model="addForm.zjhm"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">姓名：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.ksxm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-15">性别：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.xb"
                    value="男" class="form-check-input"><label for="exampleRadios1" class="form-check-label">男
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.xb"
                    value="女" class="form-check-input"><label for="exampleRadio1" class="form-check-label">女</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>考区名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="100" v-model="addForm.kqmc"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>报考级别码：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.bkjb"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>报考级别名称：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="100" v-model="addForm.bkjbmc"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>报考专业码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.bkzy"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>报考专业名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="100" v-model="addForm.bkzymc"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>专业通过时间：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.zytg"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目1成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_1"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目2成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_2"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目3成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_3"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目4成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_4"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目5成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_5"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目6成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_6"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目7成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_7"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目8成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_8"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目9成绩：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="7" v-model="addForm.cj_9"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="editDak">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
